import React from "react";
import { Label, SwitchButton, SwitchWrapper } from "./styles";

const GenderSwitch = ({ setValue, value }) => {
  // male female kid
  const handleSwitch = () => {
    if (value === "male") {
      setValue("female");
    } else if (value === "female") {
      setValue("kid");
    } else {
      setValue("male");
    }
  };

  return (
    <SwitchWrapper>
      <Label>gender:</Label>
      <SwitchButton value={value} onClick={handleSwitch}>
        <span>{value}</span>
      </SwitchButton>
    </SwitchWrapper>
  );
};

export default GenderSwitch;
