
import styled from 'styled-components';

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

`;

export const Label = styled.label`
  font-size: 14px;
  margin-bottom: 8px;
`;

export const StyledInput = styled.input`
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px;
  font-size: 16px;
`;
