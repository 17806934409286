import React, { useEffect, useState } from "react";
import Button from "../Components/Button/Button";
import Input from "../Components/Input/Input";
import GenderSwitch from "../Components/GenderSwitch/GenderSwitch";
import { H1 } from "./styles";
import { ToggleSwitch } from "../Components/ToggleSwitch/ToggleSwitch";
import { Guid } from "js-guid";
import {encodeQueryData} from "../helpers";

const MockPage = () => {
  const md5 = require("md5");
  const serverEndpoint = "https://api.e-size.io/api/v1/";

  const [userid, setUserid] = useState("1000");
  const [shopid, setShopid] = useState("1");
  const [sex, setSex] = useState("male");
  const [salt, setSalt] = useState("Kq4HoULrcqYvilG3cgUFSQ");

  const [sign, setSign] = useState("");
  const [uuid] = useState(Guid.newGuid().StringGuid);

  const [link, setLink] = useState(
    `${serverEndpoint}shop/getlink?userid=${userid}&shopid=${shopid}&sex=${sex}&sign=${sign}`
  );

  const [alt, setAlt] = useState(false);

  useEffect(() => {
    const sign = md5(userid + shopid + sex + salt);
    setSign(sign);
    if (!alt) {
      setLink(
        `${serverEndpoint}shop/getlink?userid=${userid}&shopid=${shopid}&sex=${sex}&sign=${sign}`
      );
    } else {
      const data = {
        url: `https://web.e-size.io?shopid=${shopid}&uuid=${uuid}`,
        shopid: shopid,
        uuid: uuid,
      };
      const querystring = encodeQueryData(data);
      setLink(`${serverEndpoint}tracker?${querystring}`);
    }
  }, [userid, shopid, sex, salt, alt]);

  console.log(link);

  const clickHandler = () => {
    window.location.href = link;
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        flex: 1,
        minHeight: 600,
        padding: 10,
      }}
    >
      <H1>Shop</H1>
      <ToggleSwitch checked={alt} setChecked={setAlt} />
      {!alt && (
        <Input
          label="userid"
          value={userid}
          onChange={(e) => setUserid(e.target.value)}
        />
      )}
      <Input
        label="shopid"
        value={shopid}
        onChange={(e) => setShopid(e.target.value)}
      />
      {!alt && (
        <Input
          label="salt"
          value={salt}
          onChange={(e) => setSalt(e.target.value)}
        />
      )}
      {!alt && <GenderSwitch setValue={setSex} value={sex} />}
      {!alt && <p style={{ opacity: 0.6, marginBottom: 60 }}>sign: {sign}</p>}
      {alt && <p style={{ opacity: 0.6, marginBottom: 60 }}>uuid: {uuid}</p>}
      <Button onClick={clickHandler}>Подобрать размер</Button>
      <a
        style={{
          opacity: 0.3,
          marginTop: 30,
          wordBreak: "break-all",
        }}
        href={link}
      >
        {link}
      </a>
    </div>
  );
};

export default MockPage;
