import React from 'react';
import { InputWrapper, Label, StyledInput } from './styles';

const Input = ({ label, ...rest }) => (
  <InputWrapper>
    <Label>{label}</Label>
    <StyledInput {...rest} />
  </InputWrapper>
);


export default Input;
