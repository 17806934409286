import styled from "styled-components";

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 30px;
`;

export const Label = styled.label`
  font-size: 14px;
  margin-right: 8px;
`;

export const SwitchButton = styled.button`
  background-color: ${({ value }) => (value === 'male' ? '#2683EA' : value ==='female' ? '#FF75AF': '#ffaa18')};
  color: white;
  border: none;
  border-radius: 16px;
  padding: 4px 0;
  width: 70px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
`;
