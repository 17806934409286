import styled from 'styled-components';

export const ButtonWrapper = styled.button`
  height: 64px;
  width: 100%;
  border-radius: 14px;
  background-color: ${(props) => (props.type === 'Secondary' || props.type === 'Red' ? '#F2F2F7' : '#2683ea')};
  color: ${(props) => (props.type === 'Secondary' ? '#1D1D1B' : props.type === 'Red' ? '#FF3824' : 'white')};
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
`;

export const PinBtnWrapper = styled.div`
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 16px;
  width: 100%;
`
